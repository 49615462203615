import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper"
import { navigate } from "gatsby"
import "./styles.scss"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import Button from "../../molecules/Button"
import useStaticImage from "../../../hooks/useStaticImage"
import information from "./information.json"

const Cases = () => {
  const [getImage] = useStaticImage()
  return (
    <section className="cases">
      <div className="cases__title">
        <h2>Cases</h2>
      </div>
      <div className="cases__slider">
        <Swiper
          spaceBetween={4}
          width={202}
          loop
          navigation
          loopAdditionalSlides={4}
          autoHeight
          modules={[Navigation, Pagination]}
          breakpoints={{
            769: { width: 500, spaceBetween: 10 },
          }}
        >
          {information.map(item => {
            return (
              <SwiperSlide key={item.name} onClick={() => navigate(`/cases${item.text.link}`)}>
                <GatsbyImage
                  image={getImage(item.image)}
                  alt={`${item.name}`}
                  onClick={() => navigate(`/cases${item.text.link}`)}
                />
                <div
                  className="cases__description"
                  role="none"
                >
                  <p>{item.name}</p>
                  <p>{item.text.content}</p>
                  <a href={`/cases${item.text.link}`}>Veja mais</a>
                </div>
              </SwiperSlide>
            )
          })}
          <div className="cases__button--mobile">
            <a href="/cases">
              Ver todos os cases
            </a>
          </div>
        </Swiper>
      </div>
      <div className="cases__button">
        <Button
          text="Ver todos os cases"
          classButton="primary"
          fontColor="orange-dark"
          link="cases"
        />
      </div>
    </section>
  )
}
export default Cases

import React, { useState, useCallback } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react'
import { navigate } from 'gatsby'
import useStaticImage from '../../../hooks/useStaticImage'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import information from './information.json'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import './styles.scss'

const Clients = () => {
  const [width] = useWindowDimensions()
  const [getImage] = useStaticImage()

  const [swiperRef, setSwiperRef] = useState()
  const handleLeftClick = useCallback(() => {
    swiperRef.slidePrev()
  }, [swiperRef])

  const handleRightClick = useCallback(() => {
    swiperRef.slideNext()
  }, [swiperRef])

  const handleNavigation = (href) => {
    if (href !== null) navigate(`/cases${href}`)
  }

  return (
    <section className="clients">
      <div className="clients__container">
        <div className="clients__title">
          <h2>Quem escolheu inovar</h2>
          <h2>com a DR_</h2>
        </div>
        <div className="clients__brands">
          <Swiper
            slidesPerView={2}
            onSwiper={setSwiperRef}
            spaceBetween={10}
            loop
            breakpoints={{
              400: { spaceBetween: 20, slidesPerView: 3 },
              768: { spaceBetween: 20, slidesPerView: 4 },
              900: { spaceBetween: 20, slidesPerView: 3 },
              1200: { spaceBetween: 30, slidesPerView: 4 },
            }}
            grabCursor
            loopAdditionalSlides={4}
          >
            {information.map((item) => {
              return (
                <SwiperSlide key={item.name} onClick={() => handleNavigation(item.href)}>
                  <GatsbyImage
                    className="clients__border"
                    image={getImage(item.src)}
                    alt={`${item.name}`}
                  />

                  {width >= 768 && (
                    <div className="clients__color">
                      <GatsbyImage
                        className="clients__border"
                        image={getImage(item.srcColor)}
                        alt={`${item.name}`}
                      />
                    </div>
                  )}
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        <div className="button">
          <button
            aria-label="prev"
            onClick={handleLeftClick}
            className="swiper-button-prev"
          ></button>
          <button
            aria-label="next"
            onClick={handleRightClick}
            className="swiper-button-next"
          ></button>
        </div>
      </div>
    </section>
  )
}

export default Clients

import React from 'react'
import Layout from '../templates/layout'
import Seo from '../helpers/seo'
import HighLight from '../components/organisms/HighLight'
import About from '../components/organisms/About'
import Services from '../components/organisms/Services'
import Cases from '../components/organisms/Cases'
import Clients from '../components/organisms/Clients'
import Articles from '../components/organisms/Articles'
import './styles.scss'

const IndexPage = () => {
  const Introduction = () => {
    return (
      <div className="home">
        <div className="home__slogan">
          <h1>
            Entre a ideia e a inovação há um<div>parceiro estratégico</div>
          </h1>
          <h1>
            <div> parceiro estratégico</div> <span>que faz acontecer.</span>
          </h1>
        </div>
        <div className="home__elipse"></div>
      </div>
    )
  }

  return (
    <Layout page="Home">
      <Introduction />
      <Cases />
      <Clients />
      <Services />
      <About />
      <HighLight />
      <Articles />
    </Layout>
  )
}

export const Head = () => <Seo title="Home" />

export default IndexPage

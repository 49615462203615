import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './styles.scss'
import Button from '../../molecules/Button'

const About = () => {
  return (
    <section className="about">
      <div className="about__container">
        <div className="pictures">
          <div className="pictures__img">
            <StaticImage
              src="../../../assets/images/about-1.webp"
              loading="lazy"
              objectFit="cover"
              quality={95}
              formats={['auto', 'webp', 'avif']}
              alt="Digital Republic"
            />
          </div>
          <div className="pictures__img">
            <StaticImage
              src="../../../assets/images/about-2.webp"
              loading="lazy"
              objectFit="cover"
              quality={95}
              formats={['auto', 'webp', 'avif']}
              alt="Digital Republic"
            />
          </div>
        </div>
        <div className="information">
          <div className="information__container">
            <h2>
              Sobre a <span>Digital Republic</span>
            </h2>
            <p>
              De São Paulo para o mundo, nós entregamos a tecnologia que o seu negócio
              precisa a partir de três elementos:{' '}
              <span>
                tecnologias modernas, profissionais que dominam as principais competências
                do mercado e prioridade no desenvolvimento dos nossos times.
              </span>{' '}
              Assim, atuamos para ser um parceiro estratégico de tecnologia para nossos
              clientes.
            </p>
            <p>
              Somos know-how, qualidade, comprometimento, tecnologia e, acima de tudo,{' '}
              <span>
                somos um time pronto para trazer a solução de desenvolvimento de software
                que o seu negócio precisa.
              </span>
            </p>
            <div className="information__button">
              <Button
                classButton="primary"
                text="Saiba mais"
                fontColor="orange-dark"
                link="/quem-somos"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default About

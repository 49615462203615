import React from 'react'
import Button from '../../molecules/Button'
import './styles.scss'

const Services = ({ withTitle = 'none' }) => {
  return (
    <section className={`services services-${withTitle}`}>
      <div className="services__container">
        <div className={`services__title services__title-${withTitle}`}>
          <h2>Serviços</h2>
        </div>
        <ul className="services__items">
          <li className="services__item">
            <div>
              <h3>Outsourcing e Alocação</h3>
              <span></span>
            </div>
            <div>
              <p>
                Em um ambiente empresarial dinâmico, a flexibilidade, a competência e
                rapidez são essenciais. Nosso serviço de Outsourcing e Alocação de
                desenvolvedores proporciona isso, oferecendo Squads Estendidas, Squads
                Exclusivas, e profissionais qualificados para impulsionar seus projetos.
                Com essas soluções, você pode expandir sua equipe com eficiência e
                alinhamento, garantindo um alto nível de desempenho, qualidade e
                integração cultural.
              </p>
              <Button
                classButton="secundary"
                text="Saiba mais"
                fontColor="orange-light"
                link={'/outsourcing-e-alocacao'}
              />
            </div>
          </li>
          <li className="services__item">
            <div>
              <h3>Projetos</h3>
              <span></span>
            </div>
            <div>
              <p>
                Na era da Transformação Digital, a inovação, a qualidade e a customização
                são chaves para se destacar. Nosso conjunto de serviços de projetos é
                desenhado para atender a essa necessidade, oferecendo soluções eficazes em
                desenvolvimento web, sistemas personalizados, aplicativos móveis,
                e-commerce e integrações. Cada projeto é uma oportunidade para transformar
                ideias em realidades, refletindo a identidade e os objetivos do seu
                negócio.
              </p>
              <Button
                classButton="secundary"
                text="Saiba mais"
                fontColor="orange-light"
                link={'/projetos'}
              />
            </div>
          </li>
          <li className="services__item">
            <div>
              <h3>Inovação</h3>
              <span></span>
            </div>
            <div>
              <p>
                A inovação é o motor do progresso empresarial. Nossa gama de serviços em
                inovação abrange desde a criação de MVPs e exploração de tecnologias como
                Realidade Aumentada e Inteligência Artificial, até a integração de
                soluções de IoT. Nosso foco é ajudar a identificar e implementar
                oportunidades de inovação que transformam processos e experiências,
                mantendo seu negócio na vanguarda da tecnologia.
              </p>
              <Button
                classButton="secundary"
                text="Saiba mais"
                fontColor="orange-light"
                link={'/inovacao'}
              />
            </div>
          </li>
          <li className="services__item">
            <div>
              <h3>Recrutamento em TI</h3>
              <span></span>
            </div>
            <div>
              <p>
                Encontrar o talento certo é fundamental para o sucesso de qualquer projeto
                de tecnologia. Nosso serviço de Recrutamento em TI se concentra em
                entender suas necessidades específicas e encontrar os melhores
                profissionais do mercado. Através de um processo de seleção rigoroso e
                eficiente, garantimos que você obtenha não apenas habilidades técnicas,
                mas também uma compatibilidade comportamental com sua equipe e cultura
                organizacional.
              </p>
              <Button
                classButton="secundary"
                text="Saiba mais"
                fontColor="orange-light"
                link={'/recrutamento-e-hunting'}
              />
            </div>
          </li>
          <li className="services__item">
            <div>
              <h3>AI Labs</h3>
              <span></span>
            </div>
            <div>
              <p>
              O AI Labs é um Serviço de Discovery e criação de MVP totalmente personalizado, com foco na co-criação da solução junto aos envolvidos nos processos do cliente. Utilizamos a metodologia de design thinking para entender e desenhar soluções conectadas diretamente às necessidades específicas do cliente. Em paralelo, especialistas em IA e desenvolvimento de software trabalham para identificar as melhores estratégias e ferramentas que agreguem valor rapidamente e permitam evolução contínua.
              </p>
              <Button
                classButton="secundary"
                text="Saiba mais"
                fontColor="orange-light"
                link={'/ai-labs'}
              />
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
}
export default Services

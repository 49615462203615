import React from 'react'
import './styles.scss'

const HighLight = () => (
  <section className="highLight">
    <div className="highLight__container">
      <div>
        <p>
          <span>+</span>de
        </p>
        <span>135</span>
        <p> projetos</p>
      </div>
      <div>
        <p>
          <span>+</span>de
        </p>
        <span>60</span>
        <p> clientes</p>
      </div>
      <div>
        <p>
          <span>+</span>de
        </p>
        <span>05</span>
        <p> países</p>
      </div>
    </div>
  </section>
)

export default HighLight
